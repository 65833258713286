import * as Sentry from '@sentry/react'
import { extraErrorDataIntegration } from '@sentry/browser'

const sentryDsn = import.meta.env.VITE_RUNTIME_SENTRY_DSN
const environment = import.meta.env.VITE_RUNTIME_SENTRY_ENV || import.meta.env.MODE
const commitRef = import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA || import.meta.env.VITE_GITHUB_SHA

Sentry.init({
  dsn: sentryDsn,
  environment,
  release: commitRef,
  integrations: [Sentry.browserTracingIntegration(), extraErrorDataIntegration()],
  tracesSampleRate: 0.2,
  attachStacktrace: true,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    'ChunkLoadError',
    'Non-Error exception captured',
    'Bad control character in string literal in JSON at position',
    'ResizeObserver loop',
  ],
  // @ts-expect-error: Muted so we could enable TS strict mode
  beforeSend(event) {
    if (event?.level === 'info') {
      return {
        ...event,
        exception: null,
      }
    }

    return event
  },
})
