import { Rss24Regular } from '@fluentui/react-icons'
import { t } from '@transifex/native'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationComponentProps, NavigationSectionKey } from '../../../../../helpers/navigation'
import { getFirstFeed } from '../../../../../selectors/feedsSelector'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useDefaultHomeUrl } from '../../../../hooks/useDefaultHomeUrl'
import { NavigationSection, NavigationSectionHeaderLink } from '../../primitives/NavigationSection'

import { FeedsNavigationList } from './FeedsNavigationList'

export const FeedsNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => {
    const firstFeed = useAppSelector(getFirstFeed)
    const defaultHomeUrl = useDefaultHomeUrl()

    return (
      <NavigationSection>
        <NavigationSectionHeaderLink
          label={t('Feeds')}
          isExpanded={Boolean(isExpanded)}
          ref={ref}
          to={firstFeed?.id ? { pathname: `/feeds/${firstFeed.id}` } : defaultHomeUrl}
          section={NavigationSectionKey.FEEDS}
          {...props}
        >
          <Rss24Regular className="w-7 shrink-0 text-spring.1" />
        </NavigationSectionHeaderLink>

        <FeedsNavigationList />
      </NavigationSection>
    )
  },
)
FeedsNavigationSection.displayName = 'FeedsNavigationSection'
