import { ChevronDown16Filled, ChevronUp16Filled, ChevronUpDown16Filled } from '@fluentui/react-icons'
import { Button, TableHead, cn } from '@opoint/infomedia-storybook'
import { Header, SortDirection, flexRender } from '@tanstack/react-table'

const SortingIcon = ({ sorting }: { sorting: false | SortDirection }) => {
  if (!sorting) {
    return <ChevronUpDown16Filled className="text-sky.cloudy" />
  }

  return sorting === 'asc' ? (
    <ChevronUp16Filled className="text-sky.1" />
  ) : (
    <ChevronDown16Filled className="text-sky.1" />
  )
}

const DefaultHeaderCell = <T,>({
  header,
  count,
  className,
}: {
  header: Header<T, unknown>
  className?: string
  count?: number
}) => {
  return (
    <TableHead
      className={cn(
        {
          'w-12': header.id === 'actions',
          'select-none': header.column.getCanSort(),
        },
        className,
      )}
      onClick={header.column.getToggleSortingHandler()}
    >
      {header.column.getCanSort() ? (
        <Button
          variant="outline"
          className={
            'flex w-full items-center justify-start gap-1 border-transparent bg-transparent pl-0 text-base hover:border-transparent hover:bg-transparent active:border-transparent'
          }
        >
          {flexRender(header.column.columnDef.header, header.getContext())}
          {count ? `(${count})` : ''}
          <SortingIcon sorting={header.column.getIsSorted()} />
        </Button>
      ) : (
        <>{flexRender(header.column.columnDef.header, header.getContext())}</>
      )}
    </TableHead>
  )
}

export default DefaultHeaderCell
