import { isEqual } from 'lodash-es'

import { DateData } from '../components/horizontalDatePicker/HorizontalDatePicker'
import { MailsByDate } from '../components/types/alert'
import { AlertHistoryObjectItem, CurrentAlertMailType } from '../opoint/alerts/types'
import { AlertDetailed } from '../opoint/flow'
import { CurrentAlertRecipients } from '../reducers/alerts'

import type { CommonRecipientFilter, ContactFilterWithSimple, SimpleContact } from '../components/types/contact'
import { M360Article } from '../opoint/articles/types'
import { Alert, AlertContactOverview } from '../api/opoint.schemas'
import { simpleSort } from './common'

export const extractArticlesFromAlert = (currentAlertMail?: CurrentAlertMailType[]): M360Article[] => {
  if (!currentAlertMail) {
    return []
  }

  return Object.values(currentAlertMail).flatMap((group) => (group.content ? group.content.searchresult.document : []))
}

export const formatMailsByDate = (alertHistory: AlertHistoryObjectItem): MailsByDate => {
  if (!alertHistory) {
    return {}
  }

  const dateGroups = alertHistory.results.reduce((accumulator, mail) => {
    const date = mail.date.slice(0, 10)

    // Check if the date already exists in the accumulator
    if (!accumulator[date]) {
      accumulator[date] = {
        times: [],
        failed: false,
      }
    }

    // Push all mails from the given date to its times array
    const timeExists = accumulator[date].times.some((time) => time.maillog_id === mail.maillog_id)
    if (!timeExists) {
      accumulator[date].times.push(mail)
    }

    return accumulator
  }, {})

  const dateGroupsKeys: string[] = Object.keys(dateGroups)

  // If all mails from one date failed, mark the date as failed
  dateGroupsKeys.forEach((key) => {
    const allFailed = dateGroups[key].times.every((time) => time.failed === true)
    if (allFailed) {
      dateGroups[key].failed = true
    }
  })

  return dateGroups
}

export const formatDatesForDatepicker = (mailsByDate: MailsByDate): Array<DateData> => {
  const newMapped = Object.keys(mailsByDate).map((date) => {
    return {
      value: new Date(date),
      id: date,
      isDisabled: mailsByDate[date].failed,
    }
  })

  const sortedMappedDates = newMapped.slice().sort((a, b) => new Date(a.value).getTime() - new Date(b.value).getTime())
  const finalMappedDates = [...sortedMappedDates, { id: 'next', isDisabled: false, value: null }]

  // @ts-expect-error: Muted so we could enable TS strict mode
  return finalMappedDates
}

export const getIsSendDisabled = (
  currentAlertMail: Array<CurrentAlertMailType> | undefined,
  articles: Array<M360Article>,
  isNextAlert: boolean,
  alertHistory?: AlertHistoryObjectItem,
): boolean => {
  if ((!alertHistory || alertHistory?.count === 0) && !isNextAlert) {
    return true
  }

  return !currentAlertMail || !articles.length
}

export const getIsShareDisabled = (
  currentAlertMail: Array<CurrentAlertMailType> | undefined,
  articles: Array<M360Article>,
  alertHistory?: AlertHistoryObjectItem,
): boolean => {
  return !alertHistory || alertHistory?.count === 0 || !currentAlertMail || !articles.length
}

export const getRecipients = (
  currentRecipients: AlertContactOverview,
  alert: AlertDetailed,
): CurrentAlertRecipients => {
  const mergedEmails = {}
  const mergedPhoneNumbers = []

  Object.keys(currentRecipients?.recipients || {}).forEach((groupKey) => {
    let group = currentRecipients.recipients[groupKey]
    if (groupKey === 'groups') {
      currentRecipients.recipients[groupKey].forEach((g) => {
        group = [...group, ...g.contacts]
      })
    }

    group.forEach((recipient) => {
      if (recipient.email) {
        mergedEmails[recipient.email] = {
          email: recipient.email,
          mobile: recipient.mobile || mergedEmails[recipient.email]?.mobile,
          name: recipient.name || mergedEmails[recipient.email]?.name,
          status: alert?.recipients?.find((r) => r.value === recipient.email)?.status,
        }
      } else if (recipient.mobile) {
        // @ts-expect-error: Muted so we could enable TS strict mode
        mergedPhoneNumbers.push(recipient.mobile)
      }
    })
  })

  return {
    emails: simpleSort(Object.values(mergedEmails), 'email', true),
    phoneNumbers: mergedPhoneNumbers,
  }
}

const sortIdentical = (list) => {
  list.sort((a, b) => {
    const aVal = +new Date(a.unix_timestamp * 1000)
    const bVal = +new Date(b.unix_timestamp * 1000)

    return bVal - aVal
  })

  return list
}

export const sortIdenticalArticlesInProfiles = (
  profiles: CurrentAlertMailType[],
  includeArticleInIdentical: boolean = false,
) => {
  return profiles.flatMap((profile) =>
    profile.content
      ? {
          ...profile,
          content: {
            searchresult: {
              ...profile.content.searchresult,
              document: profile.content.searchresult.document.map((document) => {
                if (!document.identical_documents?.document.length) {
                  return document
                }

                const newIdenticalDocuments = includeArticleInIdentical
                  ? [document, ...sortIdentical(document.identical_documents.document)]
                  : sortIdentical(document.identical_documents.document)
                return {
                  ...document,
                  identical_documents: {
                    cnt: document.identical_documents.cnt,
                    document: newIdenticalDocuments,
                  },
                }
              }),
            },
          },
        }
      : [],
  )
}

// Compare a recipient (email or sms) form value to the initial value
// The form value is formatted differently
export const isRecipientsFormValueDirty = (
  value: (ContactFilterWithSimple | CommonRecipientFilter)[],
  initialContacts: (ContactFilterWithSimple | CommonRecipientFilter | SimpleContact)[],
): boolean =>
  !!value &&
  !!initialContacts &&
  !isEqual(
    value.map((x) => ({ type: x.type, value: (x.entity as SimpleContact)?.value || '' })),
    initialContacts.map((x) => ({ type: x.type, value: (x as SimpleContact).value || '' })),
  )

// Compare the content form value to the initial value
export const isContentFormValueDirty = (value: number[], initialBasketIds: number[]): boolean =>
  !!value &&
  !!initialBasketIds &&
  !isEqual(
    // Array needs to be copied to not throw an error: https://stackoverflow.com/a/53420326
    // TODO: Use toSorted instead of sort after we migrate to new node version
    [...value].sort((a, b) => a - b),
    initialBasketIds.sort((a, b) => a - b),
  )

export const getFilteredAlertsBySubject = (alerts: Alert, searchTerm: string) => {
  const regex = new RegExp(searchTerm, 'i')
  return !!alerts.subject && regex.test(alerts.subject)
}
