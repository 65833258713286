import { Loader } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'

import { useEffect, useMemo, useRef } from 'react'
import { useInviteDirectListInfinite } from '../../../../../api/invite/invite'
import UserInvitationsTable from '../UserInvitationsTable'
import { InvitationSentStatusMapping, InvitationsTableItem } from '../../../../types/userManagement'
import { extractPageNumber } from '../../../../../helpers/pagination'

type Props = {
  queryParam: string
}

const InvitationsList = ({ queryParam }: Props) => {
  const loadingElement = useRef<HTMLDivElement>(null)

  const { data, error, hasNextPage, isFetching, fetchNextPage } = useInviteDirectListInfinite(
    //@ts-expect-error: fix api schema
    { page_size: 15, q: queryParam },
    {
      query: {
        keepPreviousData: true,
        getNextPageParam: (lastPage) => extractPageNumber(lastPage?.next),
        staleTime: 5000,
      },
    },
  )

  const pages = useMemo(() => data?.pages.filter(Boolean) || [], [data?.pages])
  const allData = useMemo(() => pages.flatMap((page) => page.results) || [], [pages])

  useEffect(() => {
    if (!allData.length || !hasNextPage) {
      return
    }

    const observer = new IntersectionObserver((entries) => {
      if (entries[0]?.isIntersecting) {
        void fetchNextPage()
      }
    })

    if (loadingElement.current) {
      observer.observe(loadingElement.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [allData, fetchNextPage, hasNextPage])

  if (error) {
    return (
      <div className="text-berry.2">
        <T _str="Error fetching data" />
      </div>
    )
  }

  if (!data) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader />
      </div>
    )
  }

  if (allData.length === 0) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <h2 className="mb-0 text-heading-2 text-sky.cloudy">
          <T _str="No results were found" />
        </h2>
      </div>
    )
  }

  const preparedData: InvitationsTableItem[] = allData.map((item) => {
    const status = InvitationSentStatusMapping[Number(item?.status)]

    return {
      email: item.email,
      id: Number(item.id),
      invitation: item,
      isUser: false,
      role: item.role,
      status,
    }
  })

  return (
    <>
      <div>
        <UserInvitationsTable invitationItems={preparedData} />
      </div>

      {hasNextPage && (
        <div ref={loadingElement} className="flex h-16 w-full items-center justify-center p-s">
          {isFetching && <Loader size="small" />}
        </div>
      )}
    </>
  )
}

export default InvitationsList
