import { Dismiss20Regular, Search20Regular } from '@fluentui/react-icons'
import { Input, InputGroup, InputRightElement } from '@opoint/infomedia-storybook'
import { useT } from '@transifex/react'

import { ChangeEvent, useEffect, useRef } from 'react'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { NAVIGATION_MENU_EXPANDED } from '../../../constants/localStorage'
import { useNavigationContext } from './NavigationContext'

export const NavigationSearch = () => {
  const t = useT()
  const inputRef = useRef<HTMLInputElement>(null)
  const [isExpanded, setIsExpanded] = useLocalStorage(NAVIGATION_MENU_EXPANDED, true)

  const { searchQuery, setSearchQuery } = useNavigationContext()

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleMinimizedClick = () => {
    setIsExpanded(true)
    // Assuring the input is ready to be focused
    // Can be adjusted when we have animations in place
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 100)
  }

  useEffect(() => {
    // Empty search query if toggling menu size
    setSearchQuery('')
  }, [isExpanded, setSearchQuery])

  return (
    <>
      {isExpanded ? (
        <InputGroup>
          <Input
            ref={inputRef}
            placeholder={t('Search profiles, tags and alerts')}
            onChange={handleInputChange}
            value={searchQuery}
          />
          <InputRightElement>
            {!searchQuery ? (
              <Search20Regular />
            ) : (
              <button
                type="button"
                onClick={() => {
                  setSearchQuery('')
                  inputRef.current?.focus()
                }}
                className="rounded leading-none"
              >
                <Dismiss20Regular />
                <span className="sr-only">{t('Clear')}</span>
              </button>
            )}
          </InputRightElement>
        </InputGroup>
      ) : (
        <button className={'h-[3.75rem] w-[3.75rem] hover:bg-grey.7'} onClick={handleMinimizedClick}>
          <Search20Regular />
        </button>
      )}
    </>
  )
}
