import React from 'react'
import { useRequiredParams } from '../../../hooks/useRequiredParams'
import { ErrorBreadcrumb } from '../../../breadcrumbs/ErrorBreadcrumb'
import { useProfilesRetrieve } from '../../../../api/profiles/profiles'

export const ProfileEditBreadcrumb = (): React.JSX.Element => {
  const params = useRequiredParams(['profileId'])
  const profileId = parseInt(params.profileId, 10)

  const { data, error } = useProfilesRetrieve(profileId)

  if (error) {
    return <ErrorBreadcrumb />
  }

  if (!data) {
    return <div className="h-4 w-24 animate-pulse rounded-sm bg-grey.6" />
  }

  return <>{data.name}</>
}
