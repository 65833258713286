import { CheckboxProps } from '@opoint/infomedia-storybook'
import { ComponentPropsWithoutRef, ReactElement } from 'react'

import { NavLink } from 'react-router-dom'
import isUserPermitted from '../components/common/ModulePermissions/isUserPermitted'
import { MODULES, Modules, PermissionOptions } from '../components/constants/permissions'
import { useAppSelector } from '../components/hooks/useAppSelector'
import useIsUserAdmin from '../components/hooks/useIsUserAdmin'
import { useNavigationContext } from '../components/layout/navigation/NavigationContext'
import { NavigationItem } from '../components/layout/navigation/primitives/NavigationItemMultiple'
import { AlertsNavigationSection } from '../components/layout/navigation/sections/alerts/AlertsNavigationSection'
import { CustomerViewNavigationSection } from '../components/layout/navigation/sections/CustomerViewNavigationSection'
import { DashboardsNavigationSection } from '../components/layout/navigation/sections/DashboardsNavigationSection'
import { EditAlertContentNavigationSection } from '../components/layout/navigation/sections/edit-alert-content/EditAlertContentNavigationSection'
import { FeedsNavigationSection } from '../components/layout/navigation/sections/feeds/FeedsNavigationSection'
import { FolderManagementNavigationSection } from '../components/layout/navigation/sections/FolderManagementNavigationSection'
import { SavedStatisticsNavigationSection } from '../components/layout/navigation/sections/saved-statistics/SavedStatisticsNavigationSection'
import { TagsNavigationSection } from '../components/layout/navigation/sections/tags/TagsNavigationSection'
import { TrashNavigationSection } from '../components/layout/navigation/sections/TrashNavigationSection'
import { UserManagementNavigationSection } from '../components/layout/navigation/sections/UserManagementNavigationSection'
import { Profile } from '../components/types/profile'
import { AlertTag, Tag } from '../components/types/tag'
import { Feed } from '../opoint/flow'
import { getAlertTags } from '../selectors/alertsSelectors'
import { getFeeds } from '../selectors/feedsSelector'
import { getIsAllowedToImpersonate } from '../selectors/impersonationSelectors'
import { getNewPortalDashboardURL, getUserInviteTargets } from '../selectors/settingsSelectors'
import { getStatisticsList } from '../selectors/statisticsSelectors'
import { getTags } from '../selectors/tagsSelectors'
import { getGlobalTrashTag } from '../selectors/trashTagsSelectors'

export const getNavigationProfileId = (profile: Profile) => `NavigationProfile-${profile.id}`

export const isNavigationItemChecked = (
  item: NavigationItem,
  selectedProfileIds: number[],
): CheckboxProps['checked'] => {
  if (selectedProfileIds.includes(item.id)) {
    return true
  }

  const anyChildSelected = (node: NavigationItem): boolean => {
    if (selectedProfileIds.includes(node.id)) {
      return true
    }
    if (node.children) {
      return node.children.some(anyChildSelected)
    }

    return false
  }

  if (item.children && item.children.some(anyChildSelected)) {
    return 'indeterminate'
  }

  return false
}

export enum NavigationSectionKey {
  PROFILES = 'profile',
  TAGS = 'tag',
  ALERTS = 'alerts',
  SAVED_STATISTICS = 'statistics',
  DASHBOARD = 'dashboard',
  EDIT_ALERT_CONTENT = 'editAlertContent',
  TRASH = 'trash',
  FEEDS = 'feeds',
  CUSTOMER_VIEW = 'customerView',
  FOLDER_MANAGEMENT = 'folderManagement',
  USER_MANAGEMENT = 'userManagement',
}

export const SAVED_STATISTICS_REGEX = /^\/statistics\/(\d+)(?:\/.*)?$/

export const getActiveNavigationSection = (): NavigationSectionKey | null => {
  const { search, pathname } = window.location
  const queryParams = new URLSearchParams(search)
  const filters = queryParams.get('filters') || ''
  const filtersArray = filters.split(';')

  const tagFilter = filtersArray.find((filter) => filter.includes('tag:'))
  const tagValue = tagFilter ? tagFilter.split('tag:')[1] : undefined
  const statId = /\/(\d+)/
  const isSavedStat = statId.test(pathname)

  const tagIsFirstFilter = filtersArray[0]?.includes('tag:')

  if (pathname.startsWith(`/${NavigationSectionKey.SAVED_STATISTICS}`) && isSavedStat) {
    return NavigationSectionKey.SAVED_STATISTICS
  }

  if (new RegExp(`${NavigationSectionKey.PROFILES}:\\d+`).test(filters) && !tagIsFirstFilter) {
    return NavigationSectionKey.PROFILES
  }

  // @ts-expect-error: Muted so we could enable TS strict mode
  if (new RegExp(/^\d{6}$/).test(tagValue)) {
    return NavigationSectionKey.TAGS
  }

  // @ts-expect-error: Muted so we could enable TS strict mode
  if (new RegExp(/^\d{10}$/).test(tagValue)) {
    return NavigationSectionKey.EDIT_ALERT_CONTENT
  }

  if (new RegExp(`${NavigationSectionKey.TRASH}:\\d+`).test(filters)) {
    return NavigationSectionKey.TRASH
  }

  if (new RegExp(`${NavigationSectionKey.ALERTS}/\*`).test(pathname)) {
    return NavigationSectionKey.ALERTS
  }

  if (pathname.startsWith(`/${NavigationSectionKey.FEEDS}`)) {
    return NavigationSectionKey.FEEDS
  }

  if (pathname === '/customer_view') {
    return NavigationSectionKey.CUSTOMER_VIEW
  }

  if (pathname === '/folders') {
    return NavigationSectionKey.FOLDER_MANAGEMENT
  }

  if (pathname.startsWith('/user-management')) {
    return NavigationSectionKey.USER_MANAGEMENT
  }

  return null
}

export const getIsNavigationSectionActive = (section: NavigationSectionKey) => getActiveNavigationSection() === section

export type NavOption = {
  id: NavigationSectionKey
  component: ReactElement
}

export type NavigationComponentProps = { isExpanded?: boolean } & Omit<ComponentPropsWithoutRef<typeof NavLink>, 'to'>

const NAVIGATION_OPTIONS: NavOption[] = [
  {
    id: NavigationSectionKey.TAGS,
    component: <TagsNavigationSection />,
  },
  {
    id: NavigationSectionKey.ALERTS,
    component: <AlertsNavigationSection />,
  },
  {
    id: NavigationSectionKey.SAVED_STATISTICS,
    component: <SavedStatisticsNavigationSection />,
  },
  {
    id: NavigationSectionKey.DASHBOARD,
    component: <DashboardsNavigationSection />,
  },
  {
    id: NavigationSectionKey.FEEDS,
    component: <FeedsNavigationSection />,
  },
  {
    id: NavigationSectionKey.EDIT_ALERT_CONTENT,
    component: <EditAlertContentNavigationSection />,
  },
  {
    id: NavigationSectionKey.TRASH,
    component: <TrashNavigationSection />,
  },
  {
    id: NavigationSectionKey.CUSTOMER_VIEW,
    component: <CustomerViewNavigationSection />,
  },
  {
    id: NavigationSectionKey.FOLDER_MANAGEMENT,
    component: <FolderManagementNavigationSection />,
  },
  {
    id: NavigationSectionKey.USER_MANAGEMENT,
    component: <UserManagementNavigationSection />,
  },
]

const isTagsSectionVisible = (permissions: PermissionOptions[], module: Modules, tags: Tag[]) => {
  if (!tags.length || !tags[0]?.id) {
    return false
  }

  return isUserPermitted({ module, permissions, type: 'module' })
}

const isAlertsSectionVisible = (permissions: PermissionOptions[], module: Modules) =>
  isUserPermitted({ module, permissions, type: 'module' })

const isStatisticsSectionVisible = (permissions: PermissionOptions[], module: Modules, statistics: any[]) => {
  if (!statistics.length || !statistics[0]?.id) {
    return false
  }

  return isUserPermitted({ module, permissions, type: 'module' })
}

const isDashboardSectionVisible = (dashboardURL: string | undefined) => {
  return !!dashboardURL
}

const isFeedsSectionVisible = (permissions: PermissionOptions[], module: Modules, feeds: Feed[]) => {
  if (!feeds.length || !feeds[0]?.id) {
    return false
  }

  return isUserPermitted({ module, permissions, type: 'module' })
}

const isEditAlertSectionVisible = (permissions: PermissionOptions[], module: Modules, alertTags: AlertTag[]) => {
  if (!alertTags.length || !alertTags[0]?.id) {
    return false
  }

  return isUserPermitted({ module, permissions, type: 'module' })
}

const isTrashSectionVisible = (permissions: PermissionOptions[], module: Modules, globalTrashTagId: number) => {
  if (!globalTrashTagId) {
    return false
  }

  return isUserPermitted({ module, permissions, type: 'module' })
}

const isCustomerViewSectionVisible = (isAllowedToImpersonate: boolean) => {
  return isAllowedToImpersonate
}

const isFolderManagementSectionVisible = (permissions: PermissionOptions[], module: Modules, isAdmin: boolean) => {
  if (!isAdmin) {
    return false
  }

  return isUserPermitted({ module, permissions, type: 'module' })
}

// Double-checking for the attribute due to its possible absence in some API deployments.
// Could be removed in future updates.
const isUserManagementSectionVisible = (userInviteTargets: unknown) => {
  if (Array.isArray(userInviteTargets)) {
    return !!userInviteTargets.length
  }

  return false
}

const SEARCHABLE_NAVIGATION_SECTIONS = [
  NavigationSectionKey.PROFILES,
  NavigationSectionKey.TAGS,
  NavigationSectionKey.ALERTS,
  NavigationSectionKey.SAVED_STATISTICS,
  NavigationSectionKey.FEEDS,
  NavigationSectionKey.EDIT_ALERT_CONTENT,
]

export const useNavigationSections = () => {
  const dashboardURL = useAppSelector(getNewPortalDashboardURL)
  const alertTags = useAppSelector(getAlertTags)
  const globalTrashTag = useAppSelector(getGlobalTrashTag)
  const isAllowedToImpersonate = useAppSelector(getIsAllowedToImpersonate)
  const isAdmin = useIsUserAdmin()
  const tags = useAppSelector(getTags)
  const statistics = useAppSelector(getStatisticsList)
  const feeds = useAppSelector(getFeeds)
  const userInviteTargets = useAppSelector(getUserInviteTargets)

  const { searchQuery } = useNavigationContext()

  return NAVIGATION_OPTIONS.filter(({ id }) => {
    if (searchQuery && !SEARCHABLE_NAVIGATION_SECTIONS.includes(id)) {
      return false
    }

    switch (id) {
      case NavigationSectionKey.TAGS:
        return isTagsSectionVisible([MODULES.PROFILE_MODULE.ON, MODULES.PROFILE_MODULE.READ_ONLY], 'TAG_MODULE', tags)
      case NavigationSectionKey.ALERTS:
        return isAlertsSectionVisible([MODULES.ALERT_MODULE.ON, MODULES.ALERT_MODULE.READ_ONLY], 'ALERT_MODULE')
      case NavigationSectionKey.SAVED_STATISTICS:
        return isStatisticsSectionVisible(
          [MODULES.STATISTICS_MODULE.ON, MODULES.STATISTICS_MODULE.READ_ONLY],
          'STATISTICS_MODULE',
          statistics,
        )
      case NavigationSectionKey.DASHBOARD:
        return isDashboardSectionVisible(dashboardURL)
      case NavigationSectionKey.FEEDS:
        return isFeedsSectionVisible([MODULES.FEED_MODULE.ON, MODULES.FEED_MODULE.READ_ONLY], 'FEED_MODULE', feeds)
      case NavigationSectionKey.EDIT_ALERT_CONTENT:
        return isEditAlertSectionVisible(
          [MODULES.ALERT_MODULE.ON, MODULES.ALERT_MODULE.READ_ONLY],
          'ALERT_MODULE',
          alertTags,
        )
      case NavigationSectionKey.TRASH:
        return isTrashSectionVisible(
          [MODULES.TRASH_MODULE.ON, MODULES.TRASH_MODULE.READ_ONLY],
          'TRASH_MODULE',
          // @ts-expect-error: Muted so we could enable TS strict mode
          globalTrashTag?.id,
        )
      case NavigationSectionKey.CUSTOMER_VIEW:
        return isCustomerViewSectionVisible(isAllowedToImpersonate)
      case NavigationSectionKey.FOLDER_MANAGEMENT:
        return isFolderManagementSectionVisible([MODULES.FOLDER_ADMIN.ON], 'FOLDER_ADMIN', isAdmin)
      case NavigationSectionKey.USER_MANAGEMENT:
        return isUserManagementSectionVisible(userInviteTargets)

      default:
        return false
    }
  })
}
