import { FC, PropsWithChildren } from 'react'
import { FluentIcon } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { cn } from '@opoint/infomedia-storybook'

type Props = PropsWithChildren<{
  primaryText: string
  secondaryText: string
  statusCode?: number
  className?: string
  icon: FluentIcon
}>

const ErrorPage: FC<Props> = ({ primaryText, secondaryText, statusCode, icon: Icon, children }) => (
  <div className="flex h-full w-full flex-col items-center justify-center gap-6 text-center">
    <div className="flex h-24 w-24 shrink-0 items-center justify-center rounded-full bg-sky.8">
      <Icon className="h-16 w-16 text-sky.cloudy" />
    </div>
    <div className="max-w-md">
      <p className={cn('mb-1 text-heading-1 text-sky.1', { 'mb-3': !statusCode })}>{primaryText}</p>

      {!!statusCode && (
        <span className="mb-3 block text-sm text-sky.cloudy ">
          <T _str="Error code: {statusCode}" statusCode={statusCode} />
        </span>
      )}

      <p className="mb-0 text-sm text-sky.1">{secondaryText}</p>
    </div>

    {children}
  </div>
)

export default ErrorPage
