import { captureMessage } from '@sentry/react'
import * as R from 'ramda'

import { Reducer } from 'redux'
import { getArticleId } from '@opoint/infomedia-storybook'
import { AppActions } from '../actions'
import {
  ReportArticle,
  ReportGroup,
  ReportsTagHistory,
  ReportWithNewTimesTemp,
  Source,
} from '../components/types/reports'
import * as Actions from '../constants/actionTypes'
import { keyBy } from '../helpers/common'
import { articleIdenticalIds, eqArticles, preprocessArticle } from '../opoint/articles'
import { REPORT_STATUS, REPORT_STEP_DOWNLOAD_SHARE } from '../opoint/reports'

export type ReportsState = {
  autoTranslate: boolean
  backup: {
    // there will be preserved metadata of unfished report
    order?: { [weight: string]: Array<Array<string>> } // nested Array is for ids of identicals
    toDelete?: { [weight: string]: Array<Array<string>> } // nested Array is for ids of identicals
  }
  compactListing: boolean
  contentFilter?: string
  createReportIsTakingTooLong: boolean
  dateTimeEnd?: Date
  dateTimeStart?: Date
  filterMode: 'filter' | 'highlight'
  footer: string
  preface: string
  reportsHistory?: Record<number, ReportsTagHistory | ReportWithNewTimesTemp>
  reportId: number | null
  reportObject?: object // report object from API todo
  search: {
    articles?: {
      [key: string]: Array<ReportArticle>
    }
    context?: string
    count: number
    groups?: Array<ReportGroup>
    loading: boolean
  }
  shareReportData: {
    attachment: boolean
    shareReportMessage: string
    stepNumber?: number
  }
  showDeleted: boolean
  source?: Source
  step: number
  template?: number
  title: string
  updateSoMe: boolean
  useReportHistory: boolean
}

export const initialState: ReportsState = {
  autoTranslate: false,
  backup: {
    // @ts-expect-error: Muted so we could enable TS strict mode
    order: null,
    // @ts-expect-error: Muted so we could enable TS strict mode
    toDelete: null,
  },
  compactListing: false,
  // @ts-expect-error: Muted so we could enable TS strict mode
  contentFilter: null,
  createReportIsTakingTooLong: false,
  // @ts-expect-error: Muted so we could enable TS strict mode
  dateTimeEnd: null,
  // @ts-expect-error: Muted so we could enable TS strict mode
  dateTimeStart: null,
  filterMode: 'filter',
  footer: '',
  preface: '',
  reportId: null,
  // @ts-expect-error: Muted so we could enable TS strict mode
  reportObject: null,
  // @ts-expect-error: Muted so we could enable TS strict mode
  reportsHistory: null,
  search: {
    // @ts-expect-error: Muted so we could enable TS strict mode
    articles: null,
    // @ts-expect-error: Muted so we could enable TS strict mode
    context: null,
    count: 0,
    // @ts-expect-error: Muted so we could enable TS strict mode
    groups: null,
    loading: false,
  },
  shareReportData: {
    attachment: false,
    shareReportMessage: '',
    // @ts-expect-error: Muted so we could enable TS strict mode
    stepNumber: null,
  },
  showDeleted: false,
  // @ts-expect-error: Muted so we could enable TS strict mode
  source: null,
  step: 0,
  // @ts-expect-error: Muted so we could enable TS strict mode
  template: null,
  title: '',
  updateSoMe: false,
  useReportHistory: false,
}

const reportsReducer: Reducer<ReportsState, AppActions> = (state = initialState, action): ReportsState => {
  switch (action.type) {
    case Actions.REPORTS_RESET: {
      if (!action.payload.soft || !state.search.articles) {
        return initialState
      }
      // backup metadata if modal is closed before generating report (soft reset)
      // to persist ordering and `deleted` marking

      // return 3 dimensional array: group > article > identicalArticle
      const toIdsLists = R.map(R.map(({ document }) => articleIdenticalIds(document)))

      // @ts-expect-error: Muted so we could enable TS strict mode
      return R.evolve({
        showDeleted: R.always(state.showDeleted),
        backup: {
          // @ts-expect-error: Muted so we could enable TS strict mode
          order: R.always(toIdsLists(state.search.articles)),
          // @ts-expect-error: Muted so we could enable TS strict mode
          toDelete: R.always(toIdsLists(R.map(R.filter(R.prop('deleted')))(state.search.articles))),
        },
      })(initialState)
    }
    case Actions.REPORTS_TEMPLATE: {
      const { templateId } = action.payload

      return R.assoc('template', templateId, state)
    }
    case Actions.REPORTS_SOURCE: {
      const source = R.omit(['i18n'], action.payload) // i18n is needed in some epic, but unwanted here

      // @ts-expect-error: Muted so we could enable TS strict mode
      return R.evolve({
        source: R.always(source),
        search: {
          articles: R.always(null),
        },
      })(state)
    }
    case Actions.REPORTS_DATE_START:
      // @ts-expect-error: Muted so we could enable TS strict mode
      return R.evolve({
        search: {
          articles: R.always(null),
        },
        dateTimeStart: R.always(action.payload),
        useReportHistory: R.always(false),
      })(state)
    case Actions.REPORTS_DATE_END:
      // @ts-expect-error: Muted so we could enable TS strict mode
      return R.evolve({
        search: {
          articles: R.always(null),
        },
        dateTimeEnd: R.always(action.payload),
        useReportHistory: R.always(false),
      })(state)
    case Actions.REPORTS_REPORT_USE_HISTORY: // todo fix test
      // @ts-expect-error: Muted so we could enable TS strict mode
      return R.evolve({
        search: {
          articles: R.always(null),
        },
        useReportHistory: R.always(action.payload), // todo fix naming
      })(state)
    case Actions.REPORTS_CHANGE_AUTO_TRANSLATE:
      // @ts-expect-error: Muted so we could enable TS strict mode
      return R.evolve({
        autoTranslate: R.always(action.payload),
        search: {
          articles: R.always(null),
        },
      })(state)
    case Actions.REPORTS_ARTICLES:
      return R.assocPath(['search', 'loading'], true, state)

    case Actions.REPORTS_LOAD_INITIAL_ARTICLES:
      return R.assocPath(['search', 'articles'], R.always(null), state)

    case Actions.REPORTS_ARTICLES_SUCCESS: {
      // todo test
      const {
        searchresult: {
          context = null,
          document = [],
          sort_groups = [{ name: 'Global', group: -1, weight: undefined }], // eslint-disable-line
          count,
          range_count,
        },
        alreadyPreprocessed = false,
      } = action.payload

      const groups = R.compose(
        R.indexBy(R.prop<'weight', number>('weight')),
        R.filter(({ hidden }) => !hidden),
      )(sort_groups)

      // if article has group which is not supported by backend, set the group to default one
      const groupId = ({ groupId: id }) => (groups[id] ? id : undefined)

      const preprocesedDocuments = alreadyPreprocessed
        ? document
        : R.map((a) => R.over(R.lensPath(['document']), preprocessArticle, a), document)

      // @ts-expect-error: Muted so we could enable TS strict mode
      let articles = R.groupBy(groupId, preprocesedDocuments)

      if (state.backup) {
        // apply saved `delete` marks
        const isMarked = (weight, id) => {
          const marksOfGroup = R.path<Array<Array<string>>>(['toDelete', weight])(state.backup) || []

          return R.any(R.contains(id))(marksOfGroup)
        }
        articles = R.mapObjIndexed(
          (list: ReportArticle[], weight) =>
            R.map((item: ReportArticle) => ({
              ...item,
              ...(isMarked(weight, getArticleId(item.document)) ? { deleted: true } : {}),
            }))(list),
          // @ts-expect-error: Muted so we could enable TS strict mode
        )(articles)

        // apply saved order
        const articlePosition = (weight, id) => {
          const orderOfGroup = R.path<Array<Array<string>>>(['order', weight])(state.backup) || []

          return R.findIndex(R.contains(id))(orderOfGroup)
        }
        articles = R.mapObjIndexed(
          (list: ReportArticle[], weight) =>
            R.sortBy((item: ReportArticle) => articlePosition(weight, getArticleId(item.document)))(list),
          // @ts-expect-error: Muted so we could enable TS strict mode
        )(articles)
      }

      // @ts-expect-error: Muted so we could enable TS strict mode
      return R.evolve({
        search: {
          loading: R.always(false),
          articles: R.ifElse(R.isNil, R.always(articles), R.mergeWith(R.concat, R.__, articles)),
          context: R.always(context || null),
          groups: R.ifElse(R.isNil, R.always(groups), R.merge(groups)),
          // @ts-expect-error: Muted so we could enable TS strict mode
          count: R.always(Math.max(count, range_count)),
        },
      })(state)
    }
    case Actions.REPORTS_ARTICLE_SORT: {
      const { weight, sourceIndex, targetIndex } = action.payload
      // @ts-expect-error: Muted so we could enable TS strict mode
      const articles = state['search']['articles'][weight]
      const sourceElement = articles[sourceIndex]
      articles.splice(sourceIndex, 1)
      articles.splice(targetIndex, 0, sourceElement)

      return R.assocPath(['search', 'articles', weight], articles, state)
    }
    case Actions.REPORTS_ARTICLE_MARK_TO_DELETE: {
      const { article } = action.payload

      // @ts-expect-error: Muted so we could enable TS strict mode
      return R.evolve({
        search: {
          articles: R.map(
            // map over groups
            R.map(R.when(R.compose(eqArticles(article), R.path(['document'])), R.over(R.lensPath(['deleted']), R.not))),
          ),
        },
        // @ts-expect-error: Muted so we could enable TS strict mode
      })(state)
    }
    case Actions.REPORTS_ARTICLES_FAILURE:
      return R.evolve({
        search: {
          articles: R.always({}),
          loading: R.always(false),
        },
      })(state)
    case Actions.REPORTS_FILTER_MODE:
      if (typeof action.payload !== 'string' || !['filter', 'highlight'].includes(action.payload)) {
        throw new Error('Wrong action.payload')
      }

      return R.assoc('filterMode', action.payload, state)
    case Actions.REPORTS_COMPACT_LISTING_TOGGLE:
      return R.evolve({
        compactListing: R.always(action.payload),
      })(state)
    case Actions.REPORTS_SHOW_DELETED_TOGGLE:
      return R.evolve({
        showDeleted: R.not,
      })(state)
    case Actions.REPORTS_HISTORY_FETCH:
      return R.assoc('reportsHistory', [], state)
    case Actions.REPORTS_HISTORY_FETCH_SUCCESS: {
      const { historyList } = action.payload

      if (!historyList) {
        captureMessage('Reports history is not defined', { level: 'info' })
      }

      const reportsHistory = historyList ?? []

      const preselectedHistory = reportsHistory.map((item) => {
        if (item.timestamp === '0') {
          return { ...item, selected: true }
        }

        return item
      })

      if (preselectedHistory.every((item) => !item.selected) && preselectedHistory.length > 0) {
        preselectedHistory[0].selected = true
      }

      const useReportHistory = preselectedHistory?.some((item) => item.selected)

      const historyIndexedByStimestamps = keyBy(preselectedHistory, (item) => item.stimestampUsed)

      return {
        ...state,
        reportsHistory: historyIndexedByStimestamps,
        useReportHistory,
      }
    }
    case Actions.REPORTS_HISTORY_CHECK_TOGGLE: {
      const { id } = action.payload || {}

      // @ts-expect-error: Muted so we could enable TS strict mode
      return R.evolve({
        search: {
          articles: R.always(null),
        },
        useReportHistory: R.always(true),
        reportsHistory: R.over(R.lensPath([id, 'selected']), R.not),
      })(state)
    }
    case 'REPORTS_SORT_TAG_SUCCESS': {
      // ? When refactoring this reducer, fix state.search.articles
      // ? The shape of the data is way off, array of articles is keyed by `undefined`
      // ! Keeping the current shape as it would require a lot of changes in this reducer
      const articles = state.search.articles?.['undefined'] ?? []

      const articlesToBeDeletedIds = articles
        .filter((article) => article.deleted)
        .map((article) => articleIdenticalIds(article.document))

      return {
        ...state,
        search: {
          ...state.search,
          // @ts-expect-error: Muted so we could enable TS strict mode
          articles: null,
        },
        backup: {
          // @ts-expect-error: Muted so we could enable TS strict mode
          order: null,
          toDelete: {
            // ? When refactoring this reducer, fix state.backup.toDelete
            // ? The shape of the data is way off, array of articles is keyed by `undefined`
            // ! Keeping the current shape as it would require a lot of changes in this reducer
            undefined: articlesToBeDeletedIds,
          },
        },
      }
    }
    // backup metadata if modal is closed before generating report (soft reset)
    case 'REPORTS_STEP_SUCCESS': {
      const { step } = action.payload

      return R.evolve({
        step: R.always(step),
      })(state)
    }
    case Actions.REPORTS_TITLE: {
      const { title } = action.payload

      return R.assoc('title', title, state)
    }
    case Actions.REPORTS_PREFACE: {
      const { preface } = action.payload

      return R.assoc('preface', preface, state)
    }
    case Actions.REPORTS_FOOTER: {
      const { footer } = action.payload

      return R.assoc('footer', footer, state)
    }
    case Actions.REPORTS_UPDATE_SOME_META: {
      const { updateSoMe } = action.payload

      return R.assoc('updateSoMe', updateSoMe, state)
    }
    case Actions.UPDATED_SOME_META_DATA_FAILURE: {
      return R.evolve({
        reportObject: R.assoc('error', REPORT_STATUS.UPDATE_ERROR),
      })(state)
    }
    case Actions.REPORTS_UPDATE_REPORT_ID: {
      const { reportId } = action.payload

      return R.assoc('reportId', reportId, state)
    }
    case Actions.REPORTS_CREATE:
      return R.evolve({
        reportObject: R.assoc('status', REPORT_STATUS.IN_PROGRESS),
        createReportIsTakingTooLong: R.always(false),
      })(state)
    case Actions.REPORTS_CREATE_IS_TAKING_TOO_LONG:
      return R.evolve({
        createReportIsTakingTooLong: R.always(true),
      })(state)
    case Actions.REPORTS_CREATE_SUCCESS: {
      const { reportObject } = action.payload

      return R.evolve({
        reportObject: R.always(reportObject),
        step: R.always(REPORT_STEP_DOWNLOAD_SHARE),
        backup: R.always(initialState.backup),
        reportId: R.always(null),
      })(state)
    }
    case Actions.REPORTS_CREATE_FAILURE:
      return R.evolve({
        reportObject: R.assoc('status', REPORT_STATUS.ERROR),
      })(state)
    case 'EDIT_ARTICLE_SUCCESS': {
      // todo test
      const { article } = action.payload

      // @ts-expect-error: Muted so we could enable TS strict mode
      return R.evolve({
        search: {
          articles: R.when(
            R.complement(R.isNil), // when we have the articles
            R.map(
              // map over groups
              R.map(
                // map over reportsArticle object
                R.over(
                  R.lensPath(['document']), // original searchD intercepted document
                  R.when(eqArticles(article), R.always(article)),
                ),
              ),
            ),
          ),
        },
        // @ts-expect-error: Muted so we could enable TS strict mode
      })(state)
    }
    case Actions.SHARE_REPORT_CHANGE_MESSAGE: {
      const { message } = action.payload

      return R.assocPath(['shareReportData', 'shareReportMessage'], message, state)
    }
    case Actions.SHARE_REPORT_UPDATE_STEP: {
      const { stepNumber } = action.payload

      return R.assocPath(['shareReportData', 'stepNumber'], stepNumber, state)
    }
    case Actions.SHARE_REPORT_TOGGLE_ATTACHMENT: {
      return R.assocPath(['shareReportData', 'attachment'], !state.shareReportData.attachment, state)
    }

    case Actions.CLEAR_SHARE_REPORT_DATA: {
      return R.evolve(
        {
          shareReportData: {
            attachment: R.always(false),
            shareReportMessage: R.always(''),
          },
        },
        state,
      )
    }

    default:
      return state
  }
}

export default reportsReducer
